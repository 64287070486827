import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Router, { useRouter } from "next/router";
import SideModal from "../model-hoc";
import {
  handleCancelLegacyUserSubscription,
  handlePostSubscriptionCancelPlan,
  handlePostSubscriptionChangePlan,
  handlePostSubscriptionDraftPlan,
} from "../../redux-store/actions/albums";
import { getLocal } from "../../common/function/storage";
const index = (props) => {
  const router = useRouter();
  const _ROUTES = getLocal("routers");
  const _TOKEN = getLocal("access_token");
  const dispatch = useDispatch();
  const [disablePayment, setDisablePayment] = useState(false);
  const _PLAN_WARNING_MODEL_FLAG = useSelector(
    (e) => e.common.planWarningModalFlag
  );
  const _DRAFT_API_PAYLOAD = useSelector((e) => e.memorize.draftPlanPayload);
  const _CANCEL_PLAN_FLAG = useSelector((e) => e.common.cancelPlanFlag);

  const _SUBSCRIPTION_DRAFT_PLAN = useSelector(
    (state) => state.albums.getSubscriptionDraftPlan
  );
  const close = () => {
    dispatch({ type: "PLAN_WARNING_MODEL_FLAG", payload: false });
    const body = document.querySelector("body");
    body.style.overflow = "auto";
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = _PLAN_WARNING_MODEL_FLAG === true ? "hidden" : "auto";
  }, [_PLAN_WARNING_MODEL_FLAG]);

  const handle = {
    cancelPlan: async () => {
      let res;
      if (props?.bb_user_id) {
        res = await dispatch(handleCancelLegacyUserSubscription());
      } else {
        const passParams = { cancel_at_period_end: true };
        res = await dispatch(handlePostSubscriptionCancelPlan(passParams));
      }
      if (res) {
        await Router.push("/store/explore");
      }
    },
    selectedPlan: (ele) => {
      Router.push("/change-plan");
      handle.changePlanApiCall();
      close();
    },
    draftPlan: () => {
      dispatch(handlePostSubscriptionDraftPlan(_DRAFT_API_PAYLOAD));
    },
    multiButton: async () => {
      if (
        _TOKEN &&
        (router?.pathname === "/plan" || router?.pathname === "/")
      ) {
        handle.draftPlan();
        close();
        Router.push("/change-plan");
      } else if (_TOKEN && props.cancelPlan) {
        handle.cancelPlan();

        close();
      } else if (router?.pathname === "/change-plan") {
        let passData = {
          plan_id: parseInt(_SUBSCRIPTION_DRAFT_PLAN?.change_detail?.plan_id),
          price_id: parseInt(_SUBSCRIPTION_DRAFT_PLAN?.change_detail?.price_id),
        };
        dispatch({ type: "CHECKOUT_BUTTON_CLICK", payload: true });
        await dispatch({ type: "ACCOUNT_SUBSCRIPTION_FLAG", payload: true });
        setDisablePayment(true);
        await dispatch(handlePostSubscriptionChangePlan(passData, null));
        await Router.push("/account");
        dispatch({
          type: "PLAN_WARNING_MODEL_FLAG",
          payload: false,
        });
      }
    },
  };

  return (
    <SideModal
      open={_PLAN_WARNING_MODEL_FLAG}
      name={""}
      maxWidth={500}
      className="modal"
      onClose={() => close()}
      header={<h4 style={{ color: "#DC8A6C" }} className="font-weight-normal">Warning</h4>}
      icon={"icon-cloud-arrow-up-light"}
      body={
        <div className="modal_body">
          <div className="form-group">
            <p className="text-center m-b-25 mob_top_space">
              Please notice that you will lose access to all tracks and mixes
              that belonged to the catalog they are moving away from. Are you
              sure want to do this?
            </p>
          </div>
        </div>
      }
      footer={
        <div className="footer-modal-sub-div mob-modal-btn-div">
          <div className="u_col-6 mr-5 mob-modal-btn-div">
            <button
              className="cancel-btn com-btn modal-graybtn-hover border-width2 mob-modal-cancel-btn"
              onClick={() => close()}
              type="button"
            >
              Cancel
            </button>
          </div>
          <div className="u_col-6 ml-5 mob-modal-btn-div">
            <button
              className="add-btn com-btn modal-yellowbtn-hover border-width2 box-shadow-btn-mob"
              type="button"
              disabled={disablePayment}
              onClick={() => {
                handle.multiButton();
              }}
            >
              Proceed
              {disablePayment && <i className="icon-spinner-solid-icon ml-5"></i>}
            </button>
          </div>
        </div>
      }
    ></SideModal>
  );
};

export default index;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";

import ReactTooltip from "react-tooltip";
import LoadingBar from "react-top-loading-bar";
import Router, { useRouter } from "next/router";
import Image from "next/image";

import Typography from "../../../higher-order-components/typography-hoc";
import Button from "../../../higher-order-components/button-hoc";
import PlanWarningModel from "../../../higher-order-components/plan-warning-modal/index";
import useWindowDimensions from "../../../common/function/hooks/use-window-dimensions";
import {
  handleGetAllPlans,
  handleGetPlanById,
  handleGetSubscription,
  handlePostSubscriptionChangePlan,
  handlePostSubscriptionDraftPlan,
} from "../../../redux-store/actions/albums";
import {
  getCookie,
  getLocal,
  removeLocal,
  setLocal,
} from "../../../common/function/storage";
import {
  byteSizeUnit,
  byteSizeValue,
  titleCase,
} from "../../../common/function/function";

const PricingModel = ({ upgrade }) => {
  const _TOKEN = getLocal("access_token");
  let parseDetails = getCookie("login_user") ? getCookie("login_user") : null;
  const [progress, setProgress] = useState(0);

  let emojis = {
    elementCount: 20,
    spread: 60,
    lifetime: 300,
  };

  useEffect(() => {
    if (upgrade === true) setProgress(0);
  }, [upgrade]);

  const location = useRouter();
  const router = useRouter();
  const store = useStore();
  const dispatch = useDispatch();
  const _ROUTES = getLocal("routers");
  const { width } = useWindowDimensions();
  const isMobile = width <= 767;
  const PaymentPlan = ["Annually", "Semi-annually", "Monthly"];
  const [activeIndex, setActiveIndex] = useState("");
  const [planID, setPlanId] = useState("");

  let newRef = useRef(null);
  let NewRefs = [];

  const ref = useRef(null);
  const _SELECTED_PLAN_ID = useSelector((e) => e?.memorize?.selectedPlanId);
  const _SELECTED_PAYMENT_PLAN = useSelector(
    (e) => e.memorize.selectedPaymentPlan
  );
  const _GET_SUBSCRIPTION = useSelector((e) => e.albums.getSubscription);
  const _GET_ALL_PLANS = useSelector((e) => e?.albums?.getAllPlans);
  const _PLAN_WARNING_MODEL_FLAG = useSelector(
    (e) => e.common.planWarningModalFlag
  );

  const [planArray, setPlanArray] = useState([]);

  useEffect(() => {
    dispatch(handleGetAllPlans(_ROUTES));
    if (_TOKEN) {
      dispatch(handleGetSubscription());
    }
  }, []);

  useEffect(() => {
    if (location?.pathname === "/plan") {
      setPlanArray(_GET_ALL_PLANS.slice(1));
    } else {
      setPlanArray(_GET_ALL_PLANS);
    }
  }, [_GET_ALL_PLANS]);

  useEffect(() => {
    if (location?.pathname === "/") {
      if (window.performance) {
        if (performance.navigation.type == 1) {
          removeLocal("routers");
          setLocal("routers", `/`);
        }
      }
    }
    dispatch({
      type: "SELECTED_PAYMENT_PLAN",
      payload: "Annually",
    });
  }, []);

  useEffect(() => {
    if (_SELECTED_PLAN_ID) {
      dispatch({
        type: "SELECTED_PAYMENT_PLAN",
        payload: _SELECTED_PAYMENT_PLAN,
      });
    } else {
      _GET_ALL_PLANS?.map((ele) => {
        ele?.prices?.map((ele) => {
          if (
            ele?.current_active_plan &&
            ele?.interval === "month" &&
            ele?.interval_count === "1"
          ) {
            dispatch({
              type: "SELECTED_PAYMENT_PLAN",
              payload: "Monthly",
            });
          } else if (
            ele?.current_active_plan &&
            ele?.interval === "month" &&
            ele?.interval_count === "6"
          ) {
            dispatch({
              type: "SELECTED_PAYMENT_PLAN",
              payload: "Semi-annually",
            });
          } else if (
            ele?.current_active_plan &&
            ele?.interval === "year" &&
            ele?.interval_count === "1"
          ) {
            dispatch({
              type: "SELECTED_PAYMENT_PLAN",
              payload: "Annually",
            });
          }
        });
      });
    }
  }, [_GET_ALL_PLANS]);

  const handle = {
    signUp: () => {
      Router.push("/auth/signin");
    },
    changePlanApiCall: (ele) => {
      const passParams = {
        plan_id: parseInt(ele.plan_id),
        price_id: parseInt(ele?.prices[0]?.price_id),
      };
      dispatch(handlePostSubscriptionChangePlan(passParams));
    },
  };

  const commonColorClass = useCallback((subscribePlan, PriceId) => {
    if (subscribePlan) {
      return commonFreePlanClass();
    } else if (router.pathname === "/change-plan" && subscribePlan) {
      return commonFreePlanClass();
    } else if (
      _TOKEN &&
      (router.pathname === "/" || router.pathname === "/plan") &&
      !subscribePlan
    ) {
      return " btn btn-yellow btn-block border-radius-8 m-t-35 yellow-btn-hover ";
    } else if (router.pathname === "/" && !_TOKEN) {
      return " btn btn-yellow btn-block border-radius-8 m-t-35 yellow-btn-hover ";
    } else if (_TOKEN) {
      if (router.pathname === "/change-plan" && _SELECTED_PLAN_ID === PriceId) {
        return " btn btn-white btn-block border-radius-8 m-t-35 gray-subscribe-btn-hover ";
      } else {
        return commonFreePlanClass();
      }
    } else if (!_TOKEN && _ROUTES === "/auth/signUp") {
      if (_SELECTED_PLAN_ID === PriceId) {
        return " btn btn-white btn-block border-radius-8 m-t-35 gray-subscribe-btn-hover ";
      } else {
        return commonFreePlanClass();
      }
    } else {
      return commonFreePlanClass();
    }
  });
  const commonButtonText = useCallback((subscribePlan, PriceId) => {
    if (subscribePlan) {
      return <span>Subscribed</span>;
    } else if (router.pathname === "/change-plan" && subscribePlan) {
      return <span>Subscribed</span>;
    } else if (
      _TOKEN &&
      (router.pathname === "/" || router.pathname === "/plan") &&
      !subscribePlan
    ) {
      if (_GET_SUBSCRIPTION[0]?.name === "free") {
        return <span>Upgrade</span>;
      } else {
        // return <span>Upgrade</span>;
        return <span>Select</span>;
      }
    } else if (router.pathname === "/" && !_TOKEN) {
      return <span>Subscribe</span>;
    } else if (_TOKEN) {
      if (router.pathname === "/change-plan" && _SELECTED_PLAN_ID === PriceId) {
        return <span>Selected</span>;
      } else {
        return <span>Select</span>;
      }
    } else if (!_TOKEN && _ROUTES === "/auth/signUp") {
      if (_SELECTED_PLAN_ID === PriceId) {
        return <span>Selected</span>;
      } else {
        return <span>Select</span>;
      }
    } else {
      return <span>Selected</span>;
    }
  });

  const FilterPlanArraySelectButtonColor = useCallback(
    (finalPrice, element) => {
      switch (finalPrice?.interval) {
        case "year": {
          if (_SELECTED_PAYMENT_PLAN === "Annually") {
            if (
              _GET_SUBSCRIPTION[0]?.name === element?.name &&
              _GET_SUBSCRIPTION[0]?.name === "free"
            ) {
              return commonFreePlanClass();
            } else {
              return commonColorClass(
                finalPrice?.current_active_plan,
                finalPrice?.price_id
              );
            }
          }
        }
        case "month": {
          if (
            finalPrice?.interval === "month" &&
            finalPrice?.interval_count === "6" &&
            _SELECTED_PAYMENT_PLAN === "Semi-annually"
          ) {
            if (
              _GET_SUBSCRIPTION[0]?.name === element?.name &&
              _GET_SUBSCRIPTION[0]?.name === "free"
            ) {
              return commonFreePlanClass();
            } else {
              return commonColorClass(
                finalPrice?.current_active_plan,
                finalPrice?.price_id
              );
            }
          } else if (
            finalPrice?.interval === "month" &&
            finalPrice?.interval_count === "1" &&
            _SELECTED_PAYMENT_PLAN === "Monthly"
          ) {
            if (
              _GET_SUBSCRIPTION[0]?.name === element?.name &&
              _GET_SUBSCRIPTION[0]?.name === "free"
            ) {
              return commonFreePlanClass();
            } else {
              return commonColorClass(
                finalPrice?.current_active_plan,
                finalPrice?.price_id
              );
            }
          }
        }
      }
    }
  );

  const commonFreePlanText = () => <span>Subscribed</span>;
  const commonFreePlanClass = () =>
    " btn btn-gray btn-block border-radius-8 m-t-35 pricing-gray-btn gray-subscribe-btn-hover ";

  const FilterPlanArraySelectText = useCallback((finalPrice, element) => {
    switch (finalPrice?.interval) {
      case "year": {
        if (
          _SELECTED_PAYMENT_PLAN === "Annually" &&
          finalPrice?.interval === "year" &&
          finalPrice?.interval_count === "1"
        ) {
          if (
            _GET_SUBSCRIPTION[0]?.name === element?.name &&
            _GET_SUBSCRIPTION[0]?.name === "free"
          ) {
            return commonFreePlanText();
          } else {
            return commonButtonText(
              finalPrice?.current_active_plan,
              finalPrice?.price_id,
              element
            );
          }
        }
      }
      case "month": {
        if (
          finalPrice?.interval === "month" &&
          finalPrice?.interval_count === "6" &&
          _SELECTED_PAYMENT_PLAN === "Semi-annually"
        ) {
          if (
            _GET_SUBSCRIPTION[0]?.name === element?.name &&
            _GET_SUBSCRIPTION[0]?.name === "free"
          ) {
            return commonFreePlanText();
          } else {
            return commonButtonText(
              finalPrice?.current_active_plan,
              finalPrice?.price_id,
              element
            );
          }
        } else if (
          finalPrice?.interval === "month" &&
          finalPrice?.interval_count === "1" &&
          _SELECTED_PAYMENT_PLAN === "Monthly"
        ) {
          if (
            _GET_SUBSCRIPTION[0]?.name === element?.name &&
            _GET_SUBSCRIPTION[0]?.name === "free"
          ) {
            return commonFreePlanText();
          } else {
            return commonButtonText(
              finalPrice?.current_active_plan,
              finalPrice?.price_id,
              element
            );
          }
        }
      }
    }
  });

  const commonApiCall = (passData, data) => {
    dispatch({
      type: "SELECTED_PLAN_ID",
      payload: data?.price_id,
    });
    dispatch({
      type: "IS_LOCK_SUBSCRIBE",
      payload: true,
    });
    if (_ROUTES !== "/auth/signUp") {
      dispatch(handlePostSubscriptionDraftPlan(passData));
    }
    dispatch(handleGetPlanById(data?.price_id));
    dispatch({
      type: "DRAFT_API_PAYLOAD",
      payload: passData,
    });
    dispatch({ type: "CHECKOUT_BUTTON_CLICK", payload: true });
    if (router.pathname !== "/change-plan") {
      Router.push("/change-plan");
    }
  };

  const commonMultiDispatch = (passData, data) => {
    dispatch({
      type: "PLAN_WARNING_MODEL_FLAG",
      payload: true,
    });

    setPlanId(data);
    dispatch({
      type: "SELECTED_PLAN_ID",
      payload: data?.price_id,
    });
    dispatch({
      type: "DRAFT_API_PAYLOAD",
      payload: passData,
    });
    dispatch({ type: "CHECKOUT_BUTTON_CLICK", payload: true });
  };
  const commonButtonOnCLick = (subscribePlan, data, PriceId) => {
    let passData = {
      plan_id: parseInt(data?.plan_id),
      price_id: parseInt(data?.price_id),
    };
    if (subscribePlan) {
      return null;
    } else if (router.pathname === "/change-plan" && subscribePlan) {
      return null;
    } else if (
      _TOKEN &&
      (router.pathname === "/" || router.pathname === "/plan") &&
      !subscribePlan
    ) {
      setProgress(progress + 80);
      return commonApiCall(passData, data);
    } else if (router.pathname === "/" && !_TOKEN) {
      setProgress(0);
      Router.push("/auth/signUp");
    } else if (_TOKEN) {
      if (
        router.pathname === "/change-plan" &&
        _SELECTED_PLAN_ID !== data?.price_id
      ) {
        commonApiCall(passData, data);
      }
    } else if (!_TOKEN && _ROUTES === "/auth/signUp") {
      setProgress(0);
      dispatch({
        type: "SELECTED_PLAN_ID",
        payload: data?.price_id,
      });
      dispatch(handleGetPlanById(data?.price_id));
    } else {
      commonApiCall(passData, data);
      Router.push("/change-plan");
    }
  };
  const FilterPlanArraySelectOnClick = (finalPrice, element) => {
    if (_GET_SUBSCRIPTION?.[0]?.price_id === finalPrice?.price_id) {
      dispatch({
        type: "SELECTED_PLAN_ID",
        payload: "",
      });
    } else if (!_GET_SUBSCRIPTION[0]?.price_id && element?.name === "free") {
      dispatch({
        type: "SELECTED_PLAN_ID",
        payload: finalPrice?.price_id,
      });
    }
    switch (finalPrice?.interval) {
      case "year": {
        if (
          _SELECTED_PAYMENT_PLAN === "Annually" &&
          finalPrice?.interval === "year" &&
          finalPrice?.interval_count === "1"
        ) {
          if (
            _GET_SUBSCRIPTION[0]?.name === element?.name &&
            _GET_SUBSCRIPTION[0]?.name === "free"
          ) {
            return null;
          } else {
            return commonButtonOnCLick(
              finalPrice?.current_active_plan,
              finalPrice,
              finalPrice?.price_id
            );
          }
        }
      }
      case "month": {
        if (
          finalPrice?.interval === "month" &&
          finalPrice?.interval_count === "6" &&
          _SELECTED_PAYMENT_PLAN === "Semi-annually"
        ) {
          if (
            _GET_SUBSCRIPTION[0]?.name === element?.name &&
            _GET_SUBSCRIPTION[0]?.name === "free"
          ) {
            return null;
          } else {
            return commonButtonOnCLick(
              finalPrice?.current_active_plan,
              finalPrice,
              finalPrice?.price_id
            );
          }
        } else if (
          finalPrice?.interval === "month" &&
          finalPrice?.interval_count === "1" &&
          _SELECTED_PAYMENT_PLAN === "Monthly"
        ) {
          if (
            _GET_SUBSCRIPTION[0]?.name === element?.name &&
            _GET_SUBSCRIPTION[0]?.name === "free"
          ) {
            return null;
          } else {
            return commonButtonOnCLick(
              finalPrice?.current_active_plan,
              finalPrice,
              finalPrice?.price_id
            );
          }
        }
      }
    }
  };

  const FilterPlanArray = useCallback((finalPrice) => {
    switch (finalPrice?.interval) {
      case "year": {
        if (_SELECTED_PAYMENT_PLAN === "Annually") {
          return finalPrice?.unit_amount / 100 !== 0 ? (
            <React.Fragment>
              <Typography
                variant={"body1"}
                className={"color-yellow font-weight-medium amount-text"}
              >
                ${(finalPrice?.unit_amount / 100 / 12).toFixed(2)}
              </Typography>

              <span className="per-month-text">
                <sup>/ month</sup>
              </span>
            </React.Fragment>
          ) : (
            <Typography
              variant={"body1"}
              className={"color-yellow font-weight-medium amount-text"}
            >
              Free
            </Typography>
          );
        }
      }
      case "month": {
        if (
          finalPrice?.interval === "month" &&
          finalPrice?.interval_count === "6" &&
          _SELECTED_PAYMENT_PLAN === "Semi-annually"
        ) {
          return finalPrice?.unit_amount / 100 !== 0 ? (
            <React.Fragment>
              <Typography
                variant={"body1"}
                className={"color-yellow font-weight-medium amount-text"}
              >
                ${(finalPrice?.unit_amount / 100 / 6).toFixed(2)}
              </Typography>

              <span className="per-month-text">
                <sup>/ month</sup>
              </span>
            </React.Fragment>
          ) : (
            <Typography
              variant={"body1"}
              className={"color-yellow font-weight-medium amount-text"}
            >
              Free
            </Typography>
          );
        } else if (
          finalPrice?.interval === "month" &&
          finalPrice?.interval_count === "1" &&
          _SELECTED_PAYMENT_PLAN === "Monthly"
        ) {
          return finalPrice?.unit_amount / 100 !== 0 ? (
            <React.Fragment>
              <Typography
                variant={"body1"}
                className={"color-yellow font-weight-medium amount-text"}
              >
                ${finalPrice?.unit_amount / 100}
              </Typography>

              <span className="per-month-text">
                <sup>/ month</sup>
              </span>
            </React.Fragment>
          ) : (
            <Typography
              variant={"body1"}
              className={"color-yellow font-weight-medium amount-text"}
            >
              Free
            </Typography>
          );
        }
      }
    }
  });

  const commonActiveClass = useCallback((subscribePlan, planId ) => {
    if (_SELECTED_PLAN_ID === planId) {
      return " pricing active-select-plan-shadow ";
    } else if (subscribePlan) {
      return " pricing active-price-backGround ";
    }else {
      return " pricing ";
    }
  });
  const activeClass = useCallback((finalPrice, element) => {
    switch (finalPrice?.interval) {
      case "year": {
        if (
          _SELECTED_PAYMENT_PLAN === "Annually" &&
          finalPrice?.interval === "year" &&
          finalPrice?.interval_count === "1"
        ) {
          if (
            _GET_SUBSCRIPTION[0]?.name === element?.name &&
            _GET_SUBSCRIPTION[0]?.name === "free"
          ) {
            return " pricing active-price-backGround ";
          } else {
            return commonActiveClass(finalPrice?.current_active_plan, finalPrice?.price_id);
          }
        }
      }
      case "month": {
        if (
          finalPrice?.interval === "month" &&
          finalPrice?.interval_count === "6" &&
          _SELECTED_PAYMENT_PLAN === "Semi-annually"
        ) {
          if (
            _GET_SUBSCRIPTION[0]?.name === element?.name &&
            _GET_SUBSCRIPTION[0]?.name === "free"
          ) {
            return " pricing active-price-backGround ";
          } else {
            return commonActiveClass(finalPrice?.current_active_plan, finalPrice?.price_id);
          }
        } else if (
          finalPrice?.interval === "month" &&
          finalPrice?.interval_count === "1" &&
          _SELECTED_PAYMENT_PLAN === "Monthly"
        ) {
          if (
            _GET_SUBSCRIPTION[0]?.name === element?.name &&
            _GET_SUBSCRIPTION[0]?.name === "free"
          ) {
            return " pricing active-price-backGround ";
          } else {
            return commonActiveClass(finalPrice?.current_active_plan, finalPrice?.price_id);
          }
        }
      }
    }
  });

  const myLoader = ({ src }) => src;

  // legacy user will redirect to legacy site to manage subscription
  const manageSubscriptionBtn = () => (
    <ReactTooltip
      id="connectBB"
      type="warning"
      effect="solid"
      padding="12px"
      place="bottom"
      backgroundColor="#F16529"
      event="click"
      clickable={true}
    >
      Your account is linked with{" "}
      <a
        href="https://legacy.battlebards.com/#/subscriptions"
        target="_blank"
        rel="noreferrer"
      >
        Manage Subscription
      </a>
    </ReactTooltip>
  );
  const InSideDataDesktop = useCallback((element) => (
    <div
      className={`col-xs-12 col-sm-6 ${
        planArray?.length > 3 ? "col-md-3" : "col-md-4"
      }`}
      key={element?.index}
      onClick={() => {
        parseDetails?.connected_to_bb &&
        !_GET_SUBSCRIPTION[0]?.is_stripe_subscription
          ? null
          : element?.src?.prices?.map((price) =>
              FilterPlanArraySelectOnClick(price, element?.src)
            );
      }}
    >
      <div
        className={element?.src?.prices?.map((price) =>
          activeClass(price, element?.src)
        )}
      >
        <div className="m-b-20">
          <div className="flex flex--justify-between">
            <Typography
              variant={"body1"}
              className={
                "fs-20 font-weight-normal text-left m-b-10 color-white"
              }
            >
              {titleCase(element?.src?.name)}
            </Typography>
            <div className="flex flex--align-center">
              {element?.src.name === "battlebards prime" ? (
                <Image
                  loader={myLoader}
                  src={element?.src?.images}
                  width={30}
                  height={30}
                  alt={"logo"}
                />
              ) : null}
              {element?.src.name === "plate mail games" ? (
                <Image
                  loader={myLoader}
                  src={element?.src?.images}
                  width={30}
                  height={30}
                  alt={"logo"}
                />
              ) : null}
              {element?.src.name === "epic duo" ? (
                <Image
                  loader={myLoader}
                  src={element?.src?.images}
                  width={60}
                  height={30}
                  alt={"logo"}
                />
              ) : null}
            </div>
          </div>
          <Typography
            variant={"body1"}
            className={
              "fs-14 pricing-text-0-7 m-b-40 color-white font-weight-normal"
            }
          >
            {element?.src?.description}
          </Typography>
        </div>

        <div>
          <div className="pricing-title-div m-b-40">
            <React.Fragment>
              {element?.src?.prices?.map((price) => FilterPlanArray(price))}{" "}
            </React.Fragment>
          </div>
        </div>

        <div
          className={`${
            element?.src?.name === "free" ? "hidden-div m-b-15" : "m-b-15"
          }`}
        >
          <Typography variant={"body1"} className={"fs-16 m-b-10 white-color"}>
            {element?.src?.name === "free"
              ? "Over 125 + Tracks"
              : element?.src?.name === "epic duo"
              ? "Over 5,268 Tracks"
              : element?.src?.name === "plate mail games"
              ? "Over 1,476 Tracks"
              : element?.src?.name === "battlebards prime"
              ? "Over 3,792 Tracks"
              : ""}
          </Typography>
          <Typography
            variant={"body1"}
            className={"fs-13 color-white opacity-0-7 font-weight-normal"}
          >
            and growing...
          </Typography>
        </div>
        <ul className="flex-1">
          {/* {element?.src?.planFeatures?.map((item, i) => ( */}
          <React.Fragment>
            {element?.src?.features?.map((feature, index) => (
              <li key={index}>
                <div className="flex flex--justify-between font-weight-normal">
                  <Typography variant={"body2"} className={""}>
                    {feature?.split(/(\d+)/)[0]}
                  </Typography>
                  <Typography variant={"body2"} className={""}>
                    {feature?.split(/(\d+)/)[1] +
                      " " +
                      feature?.split(/(\d+)/)[2]}
                  </Typography>
                </div>
              </li>
            ))}
            <li>
              <div className="flex flex--justify-between font-weight-normal">
                <Typography variant={"body2"} className={""}>
                  Cloud Storage
                </Typography>
                <Typography variant={"body2"} className={""}>
                  {element?.src?.metadata?.storage_in_gb === "1"
                    ? element?.src?.metadata?.storage_in_gb + " " + "GB"
                    : "100 MB"}
                </Typography>
              </div>
            </li>
          </React.Fragment>
        </ul>
        {element?.src?.metadata?.purchase_discount_in_percentage ? (
          <div className="text-center m-t-35">
            <p className="text-yellow-shadow"><span className="icon-discount-icon mr-10"></span> 20% off any track purchase</p>
          </div>
        ) : null}

        <div className="bottom-btn-div" data-for="connectBB" data-tip>
          <Button
            className={element?.src?.prices?.map((price) =>
              FilterPlanArraySelectButtonColor(price, element?.src)
            )}
            icon={""}
            position={""}
            content={element?.src?.prices?.map((price) =>
              FilterPlanArraySelectText(price, element?.src)
            )}
          ></Button>
          {parseDetails?.connected_to_bb &&
          !_GET_SUBSCRIPTION[0]?.is_stripe_subscription
            ? manageSubscriptionBtn()
            : null}
        </div>
      </div>
    </div>
  ));

  const InSideDataMobile = useCallback((element) => (
    <div
      className={`col-xs-12 col-sm-6 col-md-3 ${
        element.isActive ? "accordion accordion_isActive" : "accordion"
      }`}
      key={element?.index}
    >
      <div
        className={element?.src?.prices?.map((price) =>
          activeClass(price, element?.src)
        )}
        style={{ height: element.isActive && isMobile ? "100%" : "100%" }}
      >
        <div className="m-b-20">
          <div className="flex flex--justify-between">
            <p className="fs-20 font-weight-normal text-left m-b-10 color-white">
              {titleCase(element?.src?.name)}
            </p>
            <div className="flex flex--align-center pricing-accordion-icon">
              {element?.src.name === "battlebards prime" ? (
                <Image
                  loader={myLoader}
                  src={element?.src?.images}
                  width={30}
                  height={30}
                  alt={"logo"}
                />
              ) : null}
              {element?.src.name === "plate mail games" ? (
                <Image
                  loader={myLoader}
                  src={element?.src?.images}
                  width={30}
                  height={30}
                  alt={"logo"}
                />
              ) : null}
              {element?.src.name === "epic duo" ? (
                <Image
                  loader={myLoader}
                  src={element?.src?.images}
                  width={60}
                  height={30}
                  alt={"logo"}
                />
              ) : null}
            </div>
            <div>
              <i
                onClick={() =>
                  element.isActive
                    ? setActiveIndex("")
                    : setActiveIndex(element?.index)
                }
                className={`${
                  element.isActive
                    ? "icon-chevron-up active pricing-up-down-arrow"
                    : "icon-chevron-down pricing-up-down-arrow"
                }`}
                aria-hidden="true"
                style={{
                  zIndex: location?.pathname === "/plan" ? "99" : "999",
                }}
              ></i>
            </div>
          </div>

          <Typography
            variant={"body1"}
            className={"fs-14 pricing-text-0-7 m-b-40 font-weight-normal"}
          >
            {element?.src?.description}
          </Typography>
        </div>
        <div>
          <div className="pricing-title-div m-b-40">
            <React.Fragment>
              {element?.src?.prices?.map((price) => FilterPlanArray(price))}{" "}
            </React.Fragment>
          </div>
        </div>
        {element.isActive && (
          <React.Fragment>
            <div
              className={`${
                element?.src?.name === "free" ? "hidden-div m-b-15" : "m-b-15"
              }`}
            >
              <Typography
                variant={"body1"}
                className={"fs-16 m-b-10 white-color"}
              >
                {element?.src?.name === "free"
                  ? "Over 125 + Tracks"
                  : element?.src?.name === "epic duo"
                  ? "Over 5,268 Tracks"
                  : element?.src?.name === "plate mail games"
                  ? "Over 1,476 Tracks"
                  : element?.src?.name === "battlebards prime"
                  ? "Over 3,792 Tracks"
                  : ""}
              </Typography>
              <Typography
                variant={"body1"}
                className={"fs-13 color-white opacity-0-7 font-weight-normal"}
              >
                and growing...
              </Typography>
            </div>
            <ul className="flex-1">
              <React.Fragment>
                {element?.src?.features?.map((feature, index) => (
                  <li key={index}>
                    <div className="flex flex--justify-between font-weight-normal">
                      <Typography variant={"body2"} className={""}>
                        {feature?.split(/(\d+)/)[0]}
                      </Typography>
                      <Typography variant={"body2"} className={""}>
                        {feature?.split(/(\d+)/)[1] +
                          " " +
                          feature?.split(/(\d+)/)[2]}
                      </Typography>
                    </div>
                  </li>
                ))}
              <li>
              <div className="flex flex--justify-between font-weight-normal">
                <Typography variant={"body2"} className={""}>
                  Cloud Storage
                </Typography>
                <Typography variant={"body2"} className={""}>
                  {element?.src?.metadata?.storage_in_gb === "1"
                    ? element?.src?.metadata?.storage_in_gb + " " + "GB"
                    : "100 MB"}
                </Typography>
              </div>
            </li>
              </React.Fragment>
            </ul>

            {element?.src?.metadata?.purchase_discount_in_percentage ===
            "20" ? (
              <div className="text-center m-t-35">
                <p className="text-yellow-shadow"><span className="icon-discount-icon mr-10"></span>  20% off any track purchase</p>
              </div>
            ) : null}

            <div className="bottom-btn-div" data-for="connectBB" data-tip>
              <Button
                className={element?.src?.prices?.map((price) =>
                  FilterPlanArraySelectButtonColor(price, element?.src)
                )}
                icon={""}
                position={""}
                content={element?.src?.prices?.map((price) =>
                  FilterPlanArraySelectText(price, element?.src)
                )}
                onClick={() => {
                  element?.src?.prices?.map((price) =>
                    FilterPlanArraySelectOnClick(price, element?.src)
                  );
                }}
              ></Button>
              {parseDetails?.connected_to_bb &&
              !_GET_SUBSCRIPTION[0]?.is_stripe_subscription
                ? manageSubscriptionBtn()
                : null}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  ));

  const radioGroupButton = useCallback((ele, index) => (
    <React.Fragment key={index}>
      <input
        className="checkbox-tools"
        type="radio"
        checked={_SELECTED_PAYMENT_PLAN === ele}
        name={_SELECTED_PAYMENT_PLAN === ele}
        id={_SELECTED_PAYMENT_PLAN ? _SELECTED_PAYMENT_PLAN : ele}
      />
      <label
        className={`tab ${_SELECTED_PAYMENT_PLAN === ele ? "active_tab" : ""}`}
        htmlFor={_SELECTED_PAYMENT_PLAN === ele}
        onClick={(e) => {
          dispatch({
            type: "SELECTED_PAYMENT_PLAN",
            payload: ele,
          });
        }}
      >
        {ele}
      </label>
    </React.Fragment>
  ));

  return _GET_ALL_PLANS?.length ? (
    <div
      id="pricing"
      className={`${
        location && location?.pathname === "/plan" ? "upgrade-plan-sec" : ""
      } `}
    >
      {upgrade !== "undefined" ? null : (
        <LoadingBar
          color="#dcba6c"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
          height={4}
        />
      )}
      <section className="pricing-section">
        <div className="container">
          <div>
            <Typography
              variant={"h2"}
              className={"font-weight-medium main-title m-t-20 text-center"}
            >
              {upgrade ? "Change Plan" : "Subscribe Now"}!
            </Typography>

            <Typography
              variant={"body1"}
              className={
                "text-center m-b-30 m-t-20 fs-14 mob-pricing-space color-white opacity-0-7"
              }
            >
              Stream thousands of the greatest gaming tracks of all time with a
              BattleBards subscription.
            </Typography>

            <div className="subscribe-now-radio-div m-b-50 text-center">
              <div className="button-group-sec">
                <div className="tabs">
                  {PaymentPlan?.map((ele, index) =>
                    radioGroupButton(ele, index)
                  )}
                  <div className="glider"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="row m-t-15">
            {planArray?.map((element, index) => {
              const isActive = index === activeIndex;
              newRef = isActive;
              NewRefs.push(newRef);
              return isMobile ? (
                <InSideDataMobile
                  src={element}
                  index={index}
                  isActive={isActive}
                />
              ) : (
                <InSideDataDesktop
                  src={element}
                  index={index}
                  isActive={isActive}
                />
              );
            })}
          </div>
          {location && location?.pathname === "/auth/signUp" ? (
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className={"text-center"}>
                <Button
                  className={`button-transition-effect signup-btn-package yellow-btn-hover bg-yellow border-radius-8 color-black outline-yellow cursor-pointer p-t-10 p-b-10 p-l-20 p-r-20`}
                  position={""}
                  content={"Proceed"}
                  onClick={(e) =>
                    _SELECTED_PLAN_ID && _TOKEN
                      ? Router.push("/change-plan")
                      : _SELECTED_PLAN_ID && !_TOKEN
                      ? Router.push("/auth/email-verification")
                      : handle.signUp()
                  }
                ></Button>
              </div>
            </div>
          ) : null}
        </div>
      </section>
      {_PLAN_WARNING_MODEL_FLAG ? (
        <PlanWarningModel cancelPlan={false} plan={planID} />
      ) : null}
    </div>
  ) : null;
};

export default PricingModel;